@import 'src/vars';

.visual-interface {
    margin-bottom: 15px;

    @media only screen and (max-width: 992px) {
        border-bottom: 0;
    }

    h1 {
        @media only screen and (max-width: 992px) {
            border-bottom: 1px solid;
        }
    }

    ul {
        display: flex;
        gap: 10px;

        @media only screen and (max-width: 992px) {
            display: block;
        }

        li {
            font-size: 24px;
            padding: 10px 15px;
            border: 1px dashed;
            flex: 1;

            .sub-text {
                font-size: 16px;
                text-align: left;
                opacity: 0.8;
            }


            &:hover {
                background-color: $primaryColorBackground;
            }
            
            &.active {
                background-color: $primaryColorBackground;
                border: 1px solid;
            }
        }
    }
}