@import 'src/vars';

.audio-player {
    user-select: none;

    .audio-player-container {
        border: 1px solid;
        display: flex;
        flex-direction: column;
        width: fit-content;

        .file-name {
            border-bottom: 1px solid;
            padding: 3px 6px;
            font-size: 14px;
            padding: 2px 5px;
            border-bottom: 1px solid;
            background-color: $primaryColorBackground;
        }

        .audio-player-content {
            display: flex;
        }
    }

    .spectrum {
        canvas {
            height: 100% !important;
        }
    }
    
    .play-button-outer {
        width: 50px;
        height: 50px;
        background-color: $pageBackground;
        border-right: 1px solid;
      
      
        .button {
            margin: 0 auto;
            top: 10px;
            position: relative;  
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 15px 0 15px 30px;
            opacity: .75; 

            &.play {
                border-color: transparent transparent transparent $primaryColor;
            }

            &.stop {
                border-color: $primaryColor;
            }
        }

        &:hover {
            background-color: $primaryColor;

            .button {
                &.play {
                    border-color: transparent transparent transparent $pageBackground;
                }

                &.stop {
                    border-color: $pageBackground;
                }
            }
        }
    }
}
