@import 'src/vars';

.image-loader {
    position: relative;
    overflow: hidden;

    .loading-bars {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .bar {
            background-color: $pageBackground;
            flex: 1;
            width: 100%;
        }
    }
}


@keyframes load {
    0%   { margin-left: 0% }
    100%   { margin-left: 100% }
}