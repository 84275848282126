@import 'src/vars';

.margin-b-15 { margin-bottom: 15px !important; }
.margin-t-15 { margin-top: 15px !important; }

@font-face {
    font-family: 'clacon2';
    src: local('clacon2'), url(fonts/clacon2.ttf) format('truetype');
}

@keyframes glitch-init {
    0%   {opacity: 0}
    30%   {opacity: 1}
    35%   {opacity: 0}
    60%   {opacity: 1}
    65%   {opacity: 0}
    70%   {opacity: 1}
}

.glitch-init {
    animation-name: glitch-init;
    animation-duration: .5s;
}

.glitch-init-fast {
    animation-name: glitch-init;
    animation-duration: .25s;
}

.clear-fix {
    clear: both;
    float: none !important;
}

.only-mobile {
    display: none;
    @media only screen and (max-width: 992px) {
        display: block;
    }
}

.only-mobile-big {
    display: none;
    @media only screen and (max-width: 1400px) {
        display: block;
    }
}

button {
    background: transparent;
    border: none;
    color: $primaryColor;
    cursor: crosshair;
    font-family: $primaryFont;

    &:hover {
        background: $primaryColorBackground;
    }
}