@import "src/vars";

.side-info {

    @media only screen and (max-width: 992px) {
        margin-bottom: 15px;
        max-width: none;
        width: 100%;
    }

    .info-group:not(:last-child) {
        margin-bottom: 15px;
    }

    .data {
        font-size: 22px;
    }

    .label {
        font-size: 16px;
        opacity: 0.8;
    }	


    .image-row {
        margin-bottom: 15px;

        img {
            width: 100%;
        }
    }
}

