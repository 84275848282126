@import 'src/vars';

.education-subroutine {

    .education {
        margin-bottom: 20px;


        h1 {
            font-size: 22px;
            border-bottom: 1px dashed;
            margin-bottom: 5px;
        }

        .data {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
            opacity: 0.8;

            .dates {
                text-align: right;
            }
        }

        .images {
            display: flex;

            @media only screen and (max-width: 1400px) {
                display: block;
            }

            .image {
                width: 200px;
                border: 1px solid;
                padding: 5px;
                background-color: $primaryColorBackground;
                margin-right: 10px;

                @media only screen and (max-width: 1400px) {
                    width: calc(50% - 12px);
                    float: left;
                }

                @media only screen and (max-width: 992px) {
                    width: calc(100% - 12px);
                    float: left;
                }

            }
        }

    }

}