.experience-subroutine {

    .experience {
        margin-bottom: 20px;

        h1 {
            font-size: 22px;
            border-bottom: 1px dashed;
            margin-bottom: 5px;
        }

        .data {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
            opacity: 0.8;

            .dates {
                text-align: right;
            }
        }

    }

}