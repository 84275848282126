@import 'src/vars';

.frame-layout-container {
    border: 2px solid;

    .title {
        border-bottom: 2px solid;
        padding: 5px 10px;
        text-align: center;
        display: flex;
        position: relative;

        .title-text {
            margin: 0 10px;
        }

        .filler {
            opacity: 0.8;
            flex: 1;
            overflow: hidden;
        }

        .button-container {
            background-color: black;
            padding: 0 0 0 5px;
            position: absolute;
            right: 3px;
            top: 3px;

            button {
                font-size: 14px;
                font-family: Helvetica,Arial,sans-serif;
                background-color: $pageBackground;
                border: 1px solid;
                font-weight: 900;
                font-size: 12px;
    
                &:hover {
                    background-color: $primaryColorBackground;
                    color: $pageBackground;
                }
            }
        }

    }

    .content {
        padding: 10px;
        overflow: hidden;
    }
}