.boot-container {
    background-color: black;
    width: calc(100vw - 40px);
    //height: calc(100vh - 40px);
    height: calc(var(--doc-height) - 40px);
    font-family: clacon2;
    font-size: 24px;
    padding: 20px;
    user-select: none;

    @media screen and (max-width: 1200px) {
        font-size: 20px;
    }

    @media screen and (max-width: 900px) {
        font-size: 18px;
    }

    @media screen and (max-width: 600px) {
        font-size: 14px;
    }

    .cursor {
        font-weight: 900;
        animation-name: flash-cursor;
        animation-duration: 1s;
        animation-timing-function: step-start;
        animation-iteration-count: infinite;
    }

    .on-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        button {
            padding: 10px;
            border: 2px solid;
            border-radius: 999px;
            user-select: none;
        }

        .center {
            display: flex;
            justify-content: center;
        }

        img {
            width: 50px;
            height: 50px;
        }
    }

    .slide1 {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
    
        .logo {
            position: Absolute;
            top: 20px;
            right: 20px;
            width: 15%;
            min-width: 120px;
        }
    
        .margin-top {
            margin-top: 30px;
        }
    
        .margin-left {
            margin-left: 30px;
            @media screen and (max-width: 600px) {
                margin-left: 0px;
            }
        }
    
        .header {
            max-width: calc(100vw - 170px);
        }
    }

    .slide2 {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;


        .frame {
            border: 2px solid;
            padding: 4px;
            
        }
        .inner-frame:first-child {
            margin-bottom: 4px;
        }
        .inner-frame {
            display: flex;
            justify-content: space-between;
            border: 2px solid;
            padding: 15px;
            overflow: hidden;

            .column {
                flex: 1;
            }
        }

        .devices-block {
            margin-top: 50px;

            .table {
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
    
                .column {
                    flex: 1;
    
                    .column-header {
                        border-bottom: 2px solid;
                        padding-bottom: 10px;
                        margin-bottom: 10px;

                      

                        @media screen and (max-width: 900px) {
                            min-height: 36px;
                            margin-bottom: 20px;
                        }
                    }

                }
            }
        }

    }
    
    .slide3 {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .loading-container {
            display: flex;
            justify-content: center;

            @media screen and (max-width: 900px) {
                flex-direction: column;
                text-align: center;
            }

            > img {
                width: 100px;
                @media screen and (max-width: 900px) {
                    margin: auto;
                    margin-bottom: 20px;
                }
            }

            .text-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 20px;

                @media screen and (max-width: 900px) {
                    margin-left: 0px;
                }

                h1 {
                    font-size: 26px;
                }
            }
        }
    }
}


@keyframes flash-cursor {
    0%   {opacity: 100%}
    50%   {opacity: 0%}
}