@import 'src/vars';

.heartbeat {
    width: 150px;
    position: relative;
    overflow: hidden;
    
    img {
        width: 100%;
    }

    .layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(circle, rgba(0,0,0,0) 0%, $pageBackground 100%, $pageBackground 100%);
    }

    .fade-in {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin-left: 0%;
        background-color: $pageBackground;

        animation-name: heartbeat-in;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }

    .fade-out {
        position: absolute;
        top: 0;
        width: 200px;
        height: 100%;
        left: -150px;
        background: linear-gradient(to right, $pageBackground 0%, $pageBackground 70%, rgba(255, 255, 255, 0) 100%);

        animation-name: heartbeat-out;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }
}

@keyframes heartbeat-in {
    0%   {margin-left: 0%}
    50%   {margin-left: 100%}
    100%   {margin-left: 100%}
}

@keyframes heartbeat-out {
    0%   {left: -200px}
    50%   {left: -200px}
    100%   {left: 0}
}
