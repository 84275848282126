@import 'src/vars';

.command-line-interface {

    .commands-box {
        height: 130px;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        font-family: monospace;

        padding: 10px;
        position: relative;

        .executed-commands {
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin-bottom: 2px;

            li {
                
                &.ascii {
                    white-space: pre-wrap;
                    font-family: monospace;
                    font-size: 10px;
                }
            }
        }

        .new-command {
            display: flex;
            line-height: 0.7;

            span {
                position: absolute;
                bottom: 14px;
                left: 10px;

            }

            input {
                flex: 1;
                background: transparent;
                border: none;
                color: $primaryColor;
                margin-left: 20px;
                font-family: monospace;
            }
        }
    }
}