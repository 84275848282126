@import 'src/vars';

.projects {

    .intro {
        margin-bottom: 20px;
    }

    .projects-grid {
        display: grid;
        grid-template-columns: 32% 32% 32%;
        row-gap: 30px;
        justify-content: space-between;

        @media only screen and (max-width: 1400px) {
            grid-template-columns: 48% 48%;
        }

        @media only screen and (max-width: 992px) {
            grid-template-columns: 100%;
        }
    }

    .project-preview {
        border: 1px solid;
        display: flex;
        flex-direction: column;

        .image {
            width: calc(100% - 10px);
            padding: 5px 5px 0 5px;
            background-color: rgba(100, 115, 255, 0.5);
            height: 200px;
            background-position: center;
            background-size: cover;

            @media only screen and (max-width: 992px) {
                height: 300px;
            }

            @media only screen and (max-width: 500px) {
                height: 200px;
            }
        }

        h1 {
            padding: 5px 0 2px 0;
            border-top: 1px solid;
            border-bottom: 1px solid;
            background: rgba(100, 115, 255, 0.3);
            text-align: center;
            font-weight: 900;
        }

        .project-description {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            p {
                padding: 10px;
            }
    
            .action {
                display: flex;
                justify-content: flex-end;
                margin-top: 10px;
                
                button {
                    border: 1px solid;
                    padding: 5px 10px;
                    font-weight: 900;
                }
            }

        }
    }

    .project-view {
        h1 {
            margin-top: 15px;
            margin-bottom: 15px;
            font-size: 22px;
            border-bottom: 1px solid;
            width: 100%;
        }

        .go-back {
            button {
                border: 1px solid;
                padding: 5px 10px;
                font-weight: 900;
            }
        }

        .project-content {
            display: flex;

            @media only screen and (max-width: 1400px) {
                display: block;
            }

            > div {
                flex: 1;
            }

            .image {
                @media only screen and (max-width: 1400px) {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 20px;
                }
            }

            .content {
                padding: 0 20px;

                .status {
                    margin: 15px 0;
                    text-align: right;
                }

                .links {
                    display: flex;
                    flex-direction: column;

                    a {
                        margin-top: 10px;
                        color: $primaryColor;
                        &:hover {
                            background: $primaryColorBackground;
                        }
                    }
                }

                .iframe {
                    margin-top: 20px;
                    h2 {
                        text-align: center;
                        font-size: 20px;
                    }
                    iframe {
                        width: 100%;
                        aspect-ratio: 1/1;
                    }
                }
            }
        }
    }

}