.loading {
    .loader::after {
        content: '/';
        animation: loading 1s linear infinite;
    }
}

@keyframes loading {
    0% { content: '/' }
    25% { content: '—' }
    50% { content: '\\' }
    75% { content: '|' }
}