@import 'src/vars';

.subject {
    display: flex;
    flex-direction: column;

    .info-block {
        margin-bottom: 20px;
    }


    h1 {
        border-bottom: 1px dashed;
        margin-bottom: 15px;
        opacity: 0.8;
    }

    .two-columns {
        display: flex;
        margin-bottom: 20px;
        gap: 20px;

        @media only screen and (max-width: 992px) {
            display: block;
        }

        .column {
            flex: 1;
        }
    }

    .status {
        .info {
            text-align: center;
            display: flex;
            justify-content: flex-start;

            @media only screen and (max-width: 1200px) {
                display: block;
            }

            .heart-container {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .heart {
                    display: flex;
                    justify-content: center;
                }
            }


            .body {
                flex: 1;
                display: flex;
                justify-content: center;
                margin-bottom: 20px;

                img {
                    height: 250px;
                }
            }
        }
    }

    .information {
        .container {
            display: flex;
            gap: 20px;
        }
        img {
            height: 100px;
            border: 1px dashed;
            padding: 10px;
        }
    }

    .charts {

        .chart {
            height: 300px;

            &.radial {
                margin-bottom: 20px;
                @media only screen and (max-width: 992px) {
                    margin-bottom: 40px;
                }
            }
        }

        .skills {

            .skill-label {
                margin-bottom: 3px;
                opacity: 0.8;
            }
            .bar {
                height: 7px;
                border: 1px solid;
                margin-bottom: 10px;
                .fill {
                    height: 100%;
                    background-color: $primaryColorBackground;
                }
            }
        }

    }

    .programmer-profile-text {
        text-align: justify;
    }

    .languages {

        .lang-list {
            display: flex;
            justify-content: flex-start;
            gap: 20px;

            @media only screen and (max-width: 992px) {
                flex-direction: column;
            }
    
            .lang {
                .lang-label {
                    font-weight: 900;   
                    margin-bottom: 5px;
                }
                .lang-level {
                    opacity: 0.8;
                    margin-bottom: 5px;
                }
            }
        }
    }

    .hobbies {
        text-align: justify;

        ul {
            li {
                margin: 15px 0 0 0;
                text-align: justify;

                span {
                    //background-color: $primaryColorBackground;
                    display: block;
                    margin-bottom: 5px;
                    font-weight: 900;
                }
            }
        }
    }

}