@import 'src/vars';

* {
  cursor: crosshair;
}

body {
  background-color: $pageBackground;
  color: $primaryColor;
  font-family: $primaryFont !important;
  font-size: 16px;
}


.layout {
  margin: 100px 15%;
  display: flex;

  @media only screen and (max-width: 600px) {
    margin: 20px;
  }

  @media only screen and (max-width: 1400px) {
    margin: 50px 5%;
  }

  @media only screen and (max-width: 992px) {
    margin: 30px;
    flex-direction: column;
  }

  .layout-aside {
    width: 25%;
    @media only screen and (max-width: 992px) {
      width: 100%;
    }
  }

  .site-content {
    width: calc(75% - 10px);
    margin-left: 10px;

    @media only screen and (max-width: 992px) {
      width: 100%;
      margin-left: 40px;
      margin: 0;
    }
  }

  
}
