@import 'src/vars';

.contact {

    .section {
        margin-bottom: 20px;

        .contact-method {
            margin-top: 10px;
        }

        .email {
            text-decoration: underline;
        }

        a {
            color: $primaryColor;
            
            &:hover {
                background-color: $primaryColorBackground;
            }
        }
    }

}